.chart {
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size24);
    line-height: 30px;
    color: #1d213f;
    margin-bottom: 43px;
  }
}
