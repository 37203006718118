// $size12: "12px";
// $size13: "13px";
// $size14: "14px";
// $size16: "16px";
// $size18: "18px";
// $size24: "24px";
// $size30: "30px";
// $size36: "36px";
// $size40: "40px";
// $sizerem: "1.5rem";
.grayscale-on {
  filter: grayscale(100);
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: #f1f2f5;
  overflow-x: hidden;
  // &.grayscale-on {
  //   filter: grayscale(100);
  //   // filter: brightness(1.3);
  // }
}

.load-anim {
  animation: loadAnim 1s 1;
}

@keyframes loadAnim {
  from {
    opacity: 0;
    transform: translateY(0) scale(1);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
// fontsizes

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a {
  margin: 0;
  padding: 0;
}

.App {
  img {
    object-fit: cover;
  }
}

.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (max-width: 968px) {
    max-width: 940px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 740px;
  }

  @media only screen and (max-width: 574px) {
    max-width: 540px;
  }
}

.RoundCarusel_gallery{
    perspective: 1200px;
    overflow: hidden !important;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 400px !important;
  right: 200px !important;
  list-style: none;
  text-align: center;
}

.slick-slider {
  width: 100% !important;
}

.active {
  border-bottom: 2px solid #1d213f !important;
  color: #000 !important;
}

.overflow {
  height: 100vh;
  overflow: hidden;
}

.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: 70% !important;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 270px !important ;
  margin-left: 30px !important ;
}
.image-gallery-thumbnails-wrapper.left
  .image-gallery-thumbnails
  .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right
  .image-gallery-thumbnails
  .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
  width: 100% !important ;
  margin-bottom: 30px;
  border: 0 !important;
}
.slick-dots {
  top: 100px !important;
  left: 0;
  text-align: center !important;
  transform: translate(0px, 92px);
  li {
    button:before {
      border: 2px solid #dde1ea !important;
      box-sizing: border-box !important;
      border-radius: 50% !important;
      color: unset !important;
      opacity: unset !important;
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.slick-dots li.slick-active button:before {
  background-color: #0065dd !important;
  border: none !important;
}
.wrap_ne {
  .slick-dots {
    top: 364px !important;
    left: auto !important;
    right: auto !important;
    text-align: left !important;
    li {
      button:before {
        border: 2px solid #dde1ea !important;
        box-sizing: border-box !important;
        border-radius: 50% !important;
        color: unset !important;
        opacity: unset !important;
        width: 10px !important;
        height: 10px !important;
      }
    }
  }
  .slick-dots li.slick-active button:before {
    background-color: #0065dd !important;
  }
}
.image-gallery-thumbnail-label {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: var(--font-size16) !important;
  line-height: 21px !important;
  color: #ffffff !important;
  text-align: left !important;
  margin-top: 52px !important;
  top: unset !important;
  bottom: 0 !important;
  transform: unset !important;
  background: linear-gradient(
    180deg,
    rgba(4, 9, 42, 0) 0%,
    rgba(4, 9, 42, 0.9) 100%
  ) !important;
}
.image-gallery-slide .image-gallery-description {
  bottom: 0 !important;
}
@media only screen and (min-width: 500px) {
  .image-gallery-slide .image-gallery-description {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: var(--font-size40) !important;
    line-height: 47px !important;
    color: #ffffff !important;
    text-align: left !important;
    bottom: 0 !important;
    padding-top: 50px !important;
    padding-bottom: 20px !important;
    background: linear-gradient(
      180deg,
      rgba(4, 9, 42, 0) 0%,
      rgba(4, 9, 42, 0.9) 100%
    ) !important;
  }
}

@media only screen and (max-width: 1050px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: 60% !important;
  }
  .general_wrapper .navbar_wrapper a{
    font-size: var(--font-size12) !important;
  }
  
  .SideBar_link_btn_wrap__0AXHw .SideBar_link_btn_img__3lWod {
    display: none !important;
  }
}

.image-gallery {
  margin-left: 15px !important;
}
@media only screen and (max-width: 780px) {
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    display: none !important;
  }

  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: 0.8em;
    width: 100% !important;
    padding: 8px 15px;
  }
}
@media only screen and (max-width: 600px) {
  // .image-gallery-image {
  //   width: 41.5% !important;
  //   height: 500px;
  //   margin-left: auto !important;
  //   margin-right: auto !important;
  //   // height: 100%;
  //   // width: 100%;
  // }
  .image-gallery-description {
    width: 330px !important;
    font-size: var(--font-size24) !important;
    font-weight: normal !important;
    line-height: 28px !important;
    padding-bottom: 30px !important;
    background: linear-gradient(
      180deg,
      rgba(4, 9, 42, 0) 0%,
      rgba(4, 9, 42, 0.9) 100%
    ) !important;
  }
  .image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: left !important;
  }
  // .image-gallery-slide{
  //    width: 41.5% !important;
  //     height: 500px;
  // }
}


.slick-prev, .slick-next {
    display: none !important;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    // display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}