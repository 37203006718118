.InformationServiseLibrary {
  background: #f1f2f5;
  padding-top: 26px;
  padding-bottom: 100px;
  .InformationServiseLibrary_inner {
    margin-top: 26px;
    padding: 61px 70px 70px 70px;
    background: #ffffff;
    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size40);
      line-height: 50px;
      color: #1d213f;
      margin-bottom: 47px;
    }
    .InformationServiseLibrary_inner_btn {
      border-bottom: 1px solid #dde1ea;
      padding-bottom: 17px;
      margin-bottom: 60px;
      .inner_btn {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size13);
        line-height: 15px;
        text-transform: uppercase;
        color: #8b91a9;
        margin-right: 40px;
        text-decoration: none;
        padding-bottom: 17px;
      }
    }
  }
}
@media only screen and(max-width:500px){
  .InformationServiseLibrary  .InformationServiseLibrary_inner{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 47px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .InformationServiseLibrary .InformationServiseLibrary_inner h1{
    font-size: var(--font-size24);
    line-height: 30px;
    margin-bottom: 31px;
  }
}
