.BoardAppeal {
  background: #f1f2f5;
  padding-bottom: 100px;
  padding-top: 26px;

  .BoardAppeal_top {
    margin-top: 32px;
    padding: 63px 70px 65px 70px;
    box-sizing: border-box;
    background: #ffffff;

    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size40);
      line-height: 47px;
      color: #1d213f;
    }

    .BoardAppeal_top_header {
      display: flex;
      background: #f1f2f5;
      padding-top: 24px;
      padding-bottom: 25px;
      margin-top: 50px;

      h2 {
        width: 50%;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size16);
        line-height: 21px;
        color: #1d213f;
        margin-left: 30px;
      }
    }
  }
}

.BoardAppeal_bottom {
  padding-top: 63px;
  padding-bottom: 10px;

  .BoardAppeal_bottom_title {
    margin-bottom: 53px;

    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size30);
      line-height: 35px;
      color: #1d213f;
    }
  }

  .BoardAppeal_extra_info_title {
    margin-top: 102px;

    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size30);
      line-height: 35px;
      color: #1d213f;
    }
  }

  .BoardAppeal_extra_info_main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .BoardAppeal_extra_info_main_child {
      max-width: 300px;
      width: 100%;
      margin-top: 53px;

      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size16);
        line-height: 21px;
        color: #8b91a9;
        margin-bottom: 10px;
      }

      a {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size16);
        line-height: 21px;
        color: #0065dd;
        text-decoration: none;
      }

      h3 {
        a {
          text-decoration: none;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size14);
          line-height: 21px;
          color: #1d213f;
        }

        img {
          margin-right: 16px;
        }
      }
    }


  }
}
@media only screen and (max-width:500px) {
  .BoardAppeal .BoardAppeal_top .BoardAppeal_top_header{
    display: none;
  }
  .BoardAppeal .BoardAppeal_top h1{
    font-size:var(--font-size24) ;
  }
  .BoardAppeal .BoardAppeal_top {
    padding: 63px 20px 65px 20px;
}
.BoardAppeal{
  margin-left: 20px;
  margin-right: 20px;
}
}
