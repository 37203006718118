.InformationServiseProtocols {
  background: #f1f2f5;
  padding-top: 26px;
  padding-bottom: 100px;
  .InformationServiseProtocols_inner {
    margin-top: 26px;
    background: #ffffff;
    padding: 63px 70px;
    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size40);
      line-height: 47px;
      color: #1d213f;
      margin-bottom: 47px;
    }

    .InformationServiseProtocols_inner_main_left {
      position: sticky;
      top: 80px;
    }

    .InformationServiseProtocols_inner_main {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .InformationServiseProtocols_inner_main_right {
        width: 50%;
        h2 {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: var(--font-size24);
          line-height: 30px;
          color: #1d213f;
          margin-bottom: 24px;
        }
        h3 {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size18);
          line-height: 26px;
          color: #8b91a9;
          margin-bottom: 24px;
        }
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size18);
          line-height: 26px;
          color: #1d213f;
          margin-bottom: 53px;
        }
      }
    }
  }
}
@media only screen and (max-width:1200px) {
  .InformationServiseProtocols .InformationServiseProtocols_inner{
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .InformationServiseProtocols .InformationServiseProtocols_inner .InformationServiseProtocols_inner_main_left{
    margin-right: 20px;
  }
  
}
@media only screen and(max-width:978px) {
  .InformationServiseProtocols .InformationServiseProtocols_inner .InformationServiseProtocols_inner_main{
    flex-direction: column;
    align-items: center;
  }
    .InformationServiseProtocols .InformationServiseProtocols_inner .InformationServiseProtocols_inner_main_left{
    margin-right: 0;
    margin-bottom: 32px;
    position: unset;
  }
  .InformationServiseProtocols .InformationServiseProtocols_inner .InformationServiseProtocols_inner_main .InformationServiseProtocols_inner_main_right{
    width: 100%;
  }
  .InformationServiseProtocols .InformationServiseProtocols_inner .InformationServiseProtocols_inner_main .InformationServiseProtocols_inner_main_left img{
    width: 100%;
  }
  
}
@media only screen and(max-width:500px) {
  .InformationServiseProtocols .InformationServiseProtocols_inner .InformationServiseProtocols_inner_main .InformationServiseProtocols_inner_main_right h2{
    font-size: var(--font-size18);
  }
  .InformationServiseProtocols .InformationServiseProtocols_inner .InformationServiseProtocols_inner_main .InformationServiseProtocols_inner_main_right p{
    font-size: var(--font-size16);
  }
  .InformationServiseProtocols .InformationServiseProtocols_inner h1{
    font-size: var(--font-size24);
    line-height: 30px;
  }
  
}