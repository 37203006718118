
  
    .services__inventations__subtitle__app {
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size24);
      line-height: 30px;
      margin-bottom: 22px;
      color: #1d213f;
    }
  
    .services__inventations__link {
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size18);
      line-height: 24px;
      text-decoration: none;
      margin-bottom: 54px;
      color: #0065dd;
      display: inline-block;
    }
  
    .services__inventations__title {
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size24);
      line-height: 30px;
      margin-bottom: 47px;
      max-width: 650px;
      color: #1d213f;
    }
  
    .services__inventations__holder__inner {
      background: #f1f2f5;
      padding: 47px 80px 52px 50px;
      margin-bottom: 60px;
    }
  
    .services__inventations__flex {
      display: flex;
      justify-content: space-between;
    }
  
    .services__inventations__left {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
      li{
        width: 40%;
      }


    }
  
    .services__inventations__right {
      li:first-child {
        margin-bottom: 60px;
      }
    }
    @media only screen and (max-width:768px) {
      .services__inventations__flex{
        flex-direction: column;
      }
      .services__inventations__holder__inner{
        padding-left: 20px;
        padding-right: 20px;
      }
    }