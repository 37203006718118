* {
  font-family: Roboto;
}
.navbar_menu {
  display: none;
  overflow: scroll;
  height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }

  &.hamburger__navbar__show {
    display: block !important;
    padding: 30px 0 150px 0;
    box-sizing: border-box;
    background: #f1f2f5;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
  }
  .txt_part {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .txt_part_item {
      width: 30%;
      margin-right: 40px;
    }

    .closes {
      margin-bottom: 40px;
    }

    li {
      list-style: none;
      margin-bottom: 15px;

      a {
        font-size: var(--font-size14);
        line-height: 16px;
        color: #8b91a9;
        text-decoration: none;
      }
    }

    .footer_title {
      font-size: var(--font-size18);
      line-height: 21px;
      color: #1d213f;
      margin-bottom: 33px;
      font-weight: 500;
    }
  }

  .submenu_modal {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 460px;
    padding: 27px 30px;
    background: #ffffff;
    box-shadow: 0px 15px 50px rgba(144, 158, 187, 0.1);

    .inner_part {
      width: 50%;

      // if any sublink selected,bg color will change to .selected classname
      .selected {
        color: black;
        background: #f8f8fa;
      }

      li {
        font-size: var(--font-size16);
        color: #8b91a9;
        list-style: none;
        padding: 10px;
      }

      a {
        text-decoration: none;
        color: #8b91a9;
      }
    }
  }
}
.newone{
  display: none !important;
}
.icons{
  display: none;
}
.btn_wrapper {
  width: 300px;
  margin-top: 99px;
  display: none;
  align-items: center;
  justify-content: space-between;
  // button:nth-child(3){
  //   padding: 12px 20px;

  // }
  a {
    width: 20px;
    border: 1px solid #bcc5d6;
    padding: 10px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    &:hover {
      svg {
        fill: #0065dd;
      }
    }
  }

  a:hover {
    border: 1px solid #0065dd;
  }
}
.langua{
  display:none;
}
@media only screen and (max-width:1200px) {
  .navbar_menu.hamburger__navbar__show{
    padding-left: 30px;
  }
  .navbar_menu .txt_part{
    margin-left: 40px;
  }

  
  
}
@media only screen and (max-width:968px) {
  .navbar_menu .txt_part{
    margin-left: 20px;
  }
  .btn_wrapper{
    display:flex;
  }
  .langua{
    display: flex;
    align-items: center;
    margin-top: 50px;
    img{
      transform: rotate(90deg);
    }
    div{
      button{
        p{
          margin-left: 5px;
          background: #f1f2f5 !important;
        }
      }
    }
  }
  .newone {
    display: flex !important;
  }
  .old{
    display: none !important;
  }
  .word{
      color: #0065DD !important;
      transition: all ease 0.5s !important;
  }
  .navbar_menu .txt_part .txt_part_item:nth-child(6){
    .ule{
      height: 136px !important;
      overflow:hidden !important;
      transition: all ease 0.5s !important;

    }
  }
  .navbar_menu .txt_part .txt_part_item:nth-child(5){
    .ule{
      height: 270px !important;
      overflow:hidden !important;
      transition: all ease 0.5s !important;

    }
  }
  .navbar_menu .txt_part .txt_part_item:nth-child(4){
    .ule{
      height: 360px !important;
      overflow:hidden !important;
      transition: all ease 0.5s !important;
    }
  }
  .navbar_menu .txt_part .txt_part_item:nth-child(3){
    .ule{
      height: 550px !important;
      overflow:hidden !important;
      transition: all ease 0.5s !important;
    }
  }
  .ule{
    height: 620px !important;
    overflow:hidden !important;
    transition: all ease 0.5s !important;
    li:hover{
      color: #1D213F;
    }
  }
  .icons_open{
    transform: rotate(90deg);
    margin-right: 30px;
    color: black;
  }
  .txt_part .txt_part_item .closes{
    margin-bottom: 0;
    overflow:hidden;
    height: 0;
    transition: all ease 0.5s;
  }
  .txt_part{
    flex-direction: column;
  }
  .navbar_menu .txt_part .txt_part_item{
    width: 100%;
  }
  .navbar_menu .txt_part .txt_part_item .txt_part_item{
    display: none;
  }
  .navbar_menu .txt_part .footer_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    transition: all ease 0.5s !important;
  }
  .navbar_menu.hamburger__navbar__show{
    padding: 30px 0 150px 0
  }
  .icons{
    margin-right: 20px;
    display: block;
  }
  .navbar_menu .txt_part li{
    margin-bottom: 26px;
  }
}
@media only screen and(max-width:450px) {

    .btn_wrapper{
    width: 100%;
  }
}
