.ServiseMarksSigns{
    .ServiseMarksSigns_inner{
        p{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #1D213F;
            margin-bottom: 82px;
        }
        .ServiseMarksSigns_inner_middle{
            h2{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                color: #1D213F;
                margin-bottom: 24px;
            }
            ul{
                list-style: none;
                li{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    color: #1D213F;
                    position: relative;
                    padding-left: 20px;
                    margin-bottom: 30px;
                }
                li:after{
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background: #0065DD;
                    left: 0;
                    top: 0;
                    border-radius: 50%;
                }
                .inner{
                    margin-top: 30px;
                }
                .inner::after{
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                }
            }
        }

    }
}