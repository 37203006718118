.RegulationsInternational {
  background: #f1f2f5;
  padding-top: 26px;
  padding-bottom: 100px;
  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }
  .RegulationsInternational_container {
    margin-top: 26px;
    background: #ffffff;
    padding: 63px 100px 60px 70px;
    @media only screen and (max-width: 768px) {
      padding: 48px 40px 27px 20px;
      margin-bottom: 87px;
    }
    .RegulationsInternational_container_title {
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size40);
        line-height: 47px;
        color: #1d213f;
        margin-bottom: 48px;
        @media only screen and (max-width: 768px) {
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 28px;
         }
      }
    }

    .RegulationsInternational__wrapper {
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 0;
      }

      @media only screen and (max-width: 768px) {
        overflow-x: auto;
       }
    }

    .RegulationsInternational_container_button {
      display: flex;
      // border-bottom: 1px solid #dde1ea;
      margin-bottom: 60px;
      width: 600px;
      overflow-x: scroll !important;
      &::-webkit-scrollbar {
        width: 0;
      }
      .text {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size13);
        line-height: 15px;
        text-transform: uppercase;
        color: #8b91a9;
        margin-right: 40px;
        padding-bottom: 17px;
        text-decoration: none;
      }
    }
  }
}
