@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600&family=Open+Sans:wght@500;700&family=Poppins:wght@500&family=Roboto&display=swap");
.Collegiom {
  padding-bottom: 100px;
  background: #f1f2f5;
  padding-top: 35px;

  .Collegiom_top {
    margin-top: 35px;
    padding: 65px 70px;
    background: #ffffff;
    box-sizing: border-box;
    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size40);
      line-height: 47px;
      color: #1d213f;
    }
    .Collegiom_top_header {
      display: flex;
      background: #f1f2f5;
      padding-top: 24px;
      padding-bottom: 25px;
      margin-top: 50px;
      // margin-left: 67px;
      // margin-right: 70px;
      h2 {
        width: 50%;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size16);
        line-height: 21px;
        color: #1d213f;
        margin-left: 30px;
      }
    }
  }
  .Collegiom_bottom {
    margin-top: 102px;
    padding: 0;
    // max-width: 1200px;
    // width: 100%;
    // margin-left: auto;
    // margin-right: auto;
    // padding:63px 67px 20px 70px;
    .Collegiom_bottom_title {
      margin-bottom: 53px;
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size30);
        line-height: 35px;
        color: #1d213f;
      }
    }
    .Collegiom_bottom_main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
.Collegiom_bottom_main_child {
  margin-bottom: 30px;
  max-width: 570px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 20px 50px rgba(144, 158, 187, 0.2);
  padding-top: 30px;
  padding-bottom: 27px;
  cursor: pointer;
  span {
    margin-left: 20px;
  }
  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 21px;
    color: #222222;
  }
  h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size16);
    line-height: 21px;
    color: #1d213f;
  }
  h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 21px;
    color: #8b91a9;
  }
  img {
    margin-left: 30px;
  }
}
.Collegiom_bottom_main_child:hover {
  background-image: url("../../../assets/icons/Vector\ \(32\).png");
  background-position: 95%;
  background-repeat: no-repeat;
  background-size: auto;
}
