.PhotoPart {
  position: relative;
  margin-bottom: 60px;
  text-decoration: none;
  .PhotoPart_inner {
    position: absolute;
    z-index: 999;
    bottom: 43px;
    left: 50px;
    width: 70%;
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size14);
      line-height: 18px;
      color: #ffffff;
      margin-bottom: 10px;
    }
    h2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size30);
      line-height: 35px;
      color: #ffffff;
    }
  }
}

.photo_images {
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}
@media only screen and (max-width:1200px) {
  .PhotoPart img{
    width: 100%;
  }
  
}
@media only screen and(max-width:978px) {
  .PhotoPart .PhotoPart_inner{
    padding-top: 26px;
    padding-bottom: 41px;
    position: relative;
    bottom: 0;
    left: 0;
    width: auto;
    h2{
      color: #1D213F;
    }
    p{
      color: #1D213F;

    }
  }
  
}
@media only screen and (max-width:500px) {
  .PhotoPart .PhotoPart_inner h2{
    font-size: var(--font-size16);
    line-height: 24px;
  }
  .PhotoPart .PhotoPart_inner p {
    font-size: var(--font-size14);
    line-height: 18px;
  }

}
