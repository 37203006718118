.news__card {
  display: flex;
  align-items: flex-start;
  text-decoration: none;

  @media only screen and (max-width: 620px) {
    flex-direction: column;
    align-items: center;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dde1ea;
    padding-bottom: 41px;
  }

  &:not(:first-child) {
    padding-top: 45px;
  }

  &:hover {
    .news__card__title {
      color: #0065dd;
    }

    .news__card__date {
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size16);
      line-height: 18px;
      color: #8b91a9;
    }
  }

  &__img {
    width: 200px;
    height: 160px;
    object-fit: cover;

    @media only screen and (max-width: 620px) {
      width: 100%;
      margin-bottom: 26px;
    }
  }

  &__right {
    margin-left: 30px;

    @media only screen and (max-width: 620px) {
      margin-left: 0;
    }
  }

  &__date {
    padding-top: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 18px;
    margin-bottom: 10px;
    color: #1d213f;
    transition: all ease 0.3s;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size14);
      line-height: 18px;
     }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size18);
    line-height: 24px;
    color: #1d213f;
    transition: all ease 0.3s;
    max-width: 501px;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
      line-height: 21px;
     }
  }
}
