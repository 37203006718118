.accreditation_wrapper {
  background: #f1f2f5;
  padding: 26px 0 100px 0;
  .inner_accreditation_wrapper {
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 26px;
    padding: 61px 70px;
    background-color: white;
  }
  .arbitraj_card_wrap {
    margin-top: 50px;
    width: 44%;
    display: flex;
    padding: 30px;
    &:hover {
      box-shadow: 0px 20px 50px rgba(144, 158, 187, 0.2);
    }
    img {
      margin-right: 20px;
      width: 60px;
      height: 60px;
    }
    .donwload_wrapper {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: #8b91a9;
      }
      button {
        outline: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
      img {
        height: 17px;
        width: 20px;
      }
    }
  }
}
