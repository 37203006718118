.ad__item {
  width: 50%;
  box-sizing: border-box;
  padding-top: 45px;
  text-decoration: none;
  display: block;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  &:not(:last-child) {
    padding-bottom: 45px;
    border-bottom: 1px solid #dde1ea;
  }

  &:nth-child(even) {
    padding-left: 30px;

    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }
  }

  &:nth-last-child(2) {
    padding-bottom: 0;
    border-bottom: none;
  }

  &__date {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 18px;
    margin-bottom: 10px;
    color: #1d213f;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size14);
      line-height: 16px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size18);
    line-height: 24px;
    margin-bottom: 15px;
    color: #1d213f;
    max-width: 429px;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
      line-height: 21px;
    }
  }

  &__view {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 18px;
    padding-left: 30px;
    color: #a0a8b8;
    position: relative;

    &::before {
      content: "";
      width: 20px;
      height: 14px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../../assets/icons/eye.png);
    }
  }
}
