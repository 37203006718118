.ServicesInventionsDocumet{
    margin-left: 50px;
    margin-right: 50px;
      a{
        margin-bottom: 30px;
      }
      .laws__link {
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size18);
          line-height: 21px;
          color: #0065DD;
          text-decoration: none;
          display: block;
          padding: 23px 0;
          position: relative;
          transition: all ease 0.4s;
          font-family: "Roboto";
      
          &::after {
            content: "";
            width: 0;
            height: 0;
            overflow: hidden;
            transition: all ease 0.2s;
          }
      }
  }
  @media only screen and (max-width:500px)  {
    .ServicesInventionsDocumet{
      margin-left: 20px;
      margin-right: 20px;
    }
    
  }