.regulations {
  background: #f1f2f5;
  padding-top: 26px;
  padding-bottom: 100px;

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }

  .regulations_container {
    margin-top: 26px;
    background: #ffffff;
    padding: 60px 0px 100px 70px;

    @media only screen and (max-width: 768px) {
      padding: 48px 40px 27px 20px;
    }

    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size40);
      line-height: 47px;
      color: #1d213f;
      margin-bottom: 44px;

      @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 28px;
      }
    }
    .regulations_item {
      display: flex;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      .service__regulations__left {
        display: flex;
        flex-direction: column;
        margin-right: 140px;
        @media only screen and (max-width: 768px) {
          margin-right: 0;
        }
      }
      .service__regulations__right {
        display: flex;
        flex-direction: column;
      }
      .service__regulations__link {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size18);
        line-height: 40px;
        color: #0065dd;
        text-decoration: none;

        @media only screen and (max-width: 768px) {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
