.ServicesInventionsApplication{
    margin-left: 50px;
    margin-right: 50px;
    h2{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #0065DD;
        margin-bottom: 12px;
        margin-top: 50px;
    }
    p{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 16px;
    }
    ul{
        list-style: none;
        li{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #1D213F;
            margin-bottom: 19px; 
        }
    }
}
@media only screen and (max-width:500px) {
    .ServicesInventionsApplication{
        margin-left: 20px;
        margin-right: 20px;
    }
    .ServicesInventionsApplication h2{
        font-size: var(--font-size18);
        line-height: 21px;
    }
    .ServicesInventionsApplication p{
        font-size: var(--font-size18);
        line-height: 21px;
    }
    .ServicesInventionsApplication ul li{
        line-height: 24px;
    }
    
}