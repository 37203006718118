.activity {
  background: #f1f2f5;
  padding: 26px 0 100px 0;

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    background-color: #fff;
    padding: 63px 70px;
    box-sizing: border-box;
    margin-top: 26px;

    @media only screen and (max-width: 768px) {
      padding: 48px 40px 27px 20px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 34px;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 28px;
     }
  }

  &__wrapper {
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
     }
  }

  &__left {
    display: flex;
    flex-direction: column;
    margin-right: 200px;

    @media only screen and (max-width: 768px) {
      margin-right: 0;
     }
  }

  &__right {
    display: flex;
    flex-direction: column;
  }

  &__link {
    max-width: 300px;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 25px;
    color: #0065dd;
    text-decoration: none;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
      max-width: 100%;
     }
  }
}
