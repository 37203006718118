.file__card {
  display: flex;
  padding: 20px 25px 20px 20px;
  border: 2px solid #f1f2f5;
  align-items: center;
  background-color: #fff;
  max-width: 320px;
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;

  @media only screen and (max-width: 768px) {
    padding-right: 35px !important;
  }

  .date {
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size14);
    line-height: 21px;
    color: #222222;
    margin-bottom: 5px;
  }

  &__large {
    max-width: 100%;
    display: flex;
    padding: 20px 30px 20px 20px;
    border: 2px solid #f1f2f5;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.3s;
    // width: 570px;
    div {
      margin: 0 !important;
    }
    // &:not(:last-child) {
    //   margin-bottom: 40px;
    // }

    .file__title {
      max-width: 789px;
      margin-bottom: 5px;
      color: #1d213f;
    }

    &:hover {
      border: 2px solid #f1f2f5;
      box-sizing: border-box;
      box-shadow: 0px 10px 50px rgba(144, 158, 187, 0.2);
    }
  }

  .file__img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 15px;
    align-self: flex-start !important;

    @media only screen and (max-width: 768px) {
      align-self: flex-start !important;
    }
  }

  .file__title {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 21px;
    max-width: 589px;
    color: #1d213f;
  }

  .file__memory {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 21px;
    color: #8b91a9;
    display: inline-block;
  }

  .file__download {
    visibility: hidden;
  }
  a {
    margin-left: auto;
  }
  &:hover {
    .file__download {
      visibility: visible;
      width: 17px;
      height: 20px;
      object-fit: cover;
    }
  }
}
