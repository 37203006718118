.ServicesInventionsRules{
    margin-left: 50px;
    margin-right: 50px;
    .ServicesInventionsRules_top{
        h1{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            color: #000000;
            margin-bottom: 11px;
        }
        p{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #1D213F;
            margin-bottom: 85px;
        }
    }
    .ServicesInventionsRules_bottom{
        margin-bottom: 87px;
        .ServicesInventionsRules_bottom_left{
            h2{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                color: #000000;
                margin-bottom: 22px;
            } 
        }
        .ServicesInventionsRules_bottom_right{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            span{
                width: 70%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                &:nth-child(2){
                    width: 30%
                }
            }
            img{
                max-width: 220px;
                width: 100%;
                margin-bottom: 30px;
                background: #F1F2F5;
            }
        }
    }
}
@media only screen and (max-width:700px) {
    .ServicesInventionsRules .ServicesInventionsRules_bottom .ServicesInventionsRules_bottom_right{
        flex-direction: column-reverse;
    }
    .ServicesInventionsRules .ServicesInventionsRules_bottom .ServicesInventionsRules_bottom_right span{
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        &:nth-child(2){
            width: 100% !important;
            justify-content: space-around;
        }
    }
    
}
@media only screen and (max-width:500px) {
    .ServicesInventionsRules{
        margin-left: 20px;
        margin-right: 20px;
    }

    
}