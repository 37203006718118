@mixin spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin btnStyleNone {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.link_card {
  display: flex;
  justify-content: space-between;
  .useful_links {
    @include spaceBetween;
    padding-bottom: 36px;
    border-bottom: 1px solid #dde1ea;
    width: 100%;

    .slide_txt {
      font-size: var(--font-size30);
      line-height: 35px;
      color: #1d213f;
      font-weight: 500;
    }

    .btn_wrapper {
      @include spaceBetween;
      width: 8%;

      button {
        @include btnStyleNone;
      }
    }
  }
}

//////////////

.linkCard_wrap {
  width: 100%;
  padding-top: 39px;
  text-align: center;
  justify-content: center;
  align-items: center;
  .linkCard_wraper {
    justify-content: center;
    width: 100%;
    max-width: 120px !important;
  }
  .link_card {
    border-radius: 50%;
    background: #f1f2f5;
    padding: 15px 18px;
    margin-left: auto;
    margin-right: auto;
  }
  .link_txt1 {
    margin-top: 26px;
    margin-bottom: 10px;
    font-size: var(--font-size14);
    line-height: 18px;
    color: #8b91a9;
  }
  .link_txt2 a {
    font-size: var(--font-size16);
    line-height: 21px;
    color: #0065dd;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1200px) {
  .position {
    position: relative;
    margin: 0 20px;
    border-bottom: 1px solid grey;
    padding-bottom: 40px;
  }
  .btn_wrapper {
    width: 100px !important;
  }
}

@media only screen and (max-width: 900px) {
  .position {
    position: relative;
    margin: 0 20px;
    border-bottom: 1px solid grey;
    padding-bottom: 40px;
  }
  .btn_wrapper {
    width: 100px !important;
  }
}

@media only screen and (max-width: 400px) {
  .position {
    position: relative;
    margin: 0 20px;
    border-bottom: 1px solid #dde1ea;
    padding-bottom: 40px;
  }
  .btn_wrapper {
    position: absolute;
    top: 130px;
    width: 100% !important;
    z-index: 8555555;
  }
}
