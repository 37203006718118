.activity__corruption {
  background: #f1f2f5;
  padding: 26px 0 100px 0;

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    background-color: #fff;
    padding: 63px 70px;
    margin-top: 26px;

    @media only screen and (max-width: 768px) {
      padding: 48px 20px 27px 20px;
      margin-bottom: 87px;
      overflow-x: hidden;
    }
  }

  &__nav__wrapper {
    overflow-x: hidden;
    // border-bottom: 1px solid #dde1ea;
    margin-bottom: 0px;
    // border-bottom: 1px solid #dde1ea;

    &::-webkit-scrollbar {
      width: 0;
    }

    @media only screen and (max-width: 768px) {
      overflow-x: auto;
     }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 48px;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 28px;
     }
  }

  &__navigation {
    display: flex;
    align-items: center;
    width: 800px;
    overflow-x: scroll !important;
    padding: 0 !important;

    &::-webkit-scrollbar {
      width: 0;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
     }
  }



  &__nav {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size13);
    line-height: 15px;
    text-transform: uppercase;
    color: #8b91a9;
    text-decoration: none;
    display: block;
    padding: 0 20px 17px;

    

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.active {
      color: #000;
    }
  }
}
