.question_answer_wrapper {
  background-color: #f1f2f5;
  padding: 36px 0 100px 0;
  .question_answer_wrapper_inner {
    max-width: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 27px;
    background-color: #fff;
    padding: 63px 70px;
  }
  .left_content {
    border: 1px solid #dde1ea;
    box-sizing: border-box;
    padding: 26px 30px;
    width: 30%;
    position: sticky;
    top: 80px;
    p {
      border-bottom: 1px solid #dde1ea;
      padding-bottom: 26px;
      cursor: pointer;
    }
  }
  .question_answer_title {
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
  }
  .question_content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 50px;
  }
  .question_title {
    // font-size: var(--font-size24);
    line-height: 28px;
    color: #0065dd;
    margin-bottom: 26px;
  }
  .question_title2 {
    font-size: var(--font-size24);
    line-height: 28px;
    color: #1d213f;
    margin-bottom: 36px;
    font-weight: 500;
  }
  .outer_title {
    margin-bottom: 30px;
  }
  .right_content {
    margin-left: 100px;
    width: 70%;
  }
  .question_dropdown_item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // height: 0;
    p {
      border-bottom: 1px solid #dde1ea;
      padding-bottom: 18px;
      font-size: var(--font-size18);
      line-height: 24px;
      color: #1d213f;
      margin-top: 32px;
      width: 100%;
    }

    // button
  }
  .running {
    // max-height: 0px;
    display: none;
  }
  .inner_accordion {
    display: block;
    max-height: 400px;
    overflow: hidden;
    .ul_title {
      margin: 22px 0 31px 0;
    }
    .accordion_item {
      list-style: none;
    }
    li {
      margin-bottom: 46px;
      list-style: none;
      display: flex;
      align-items: flex-start;
      // justify-content: space-between;
    }
    .li_img {
      margin-right: 20px;
      margin-top: 2px;
    }
  }
}
@media only screen and (max-width: 370px) {
  .question_answer_wrapper_inner {
    padding: 47px 20px !important;

    .question_answer_title {
      font-size: var(--font-size24) !important;
      line-height: 28px !important;
    }
    .left_content {
      border: none;
      border-bottom: 1px solid #dde1ea;
      box-sizing: border-box;
      padding: 0;
      display: flex;
      overflow: scroll;
      width: 100%;
      align-items: baseline;
      padding-bottom: 0 !important;
      margin-bottom: 43px;
      position: inherit !important;
      &::-webkit-scrollbar {
        width: 0;
      }
      p {
        white-space: nowrap;
        margin-right: 20px;
        border-bottom: none;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
      }
    }
    .question_title {
      color: #8b91a9;
    }
    .question_title2 {
      font-size: var(--font-size18);
      line-height: 21px;
      white-space: nowrap;
    }
    .question_content {
      flex-direction: column;
      margin-top: 32px;
    }
    .right_content {
      margin-left: 0;
      width: 100% !important;
    }
  }
  .question_dropdown_item{
    display: flex;
    flex-direction: row-reverse;
    .dropdown_txt{
      margin-left: 20px;
    }
}
}

@media only screen and (max-width: 900px) {
  .question_answer_wrapper_inner {
    padding: 47px 20px !important;

    .question_answer_title {
      font-size: var(--font-size24) !important;
      line-height: 28px !important;
    }
    .left_content {
      border: none;
      border-bottom: 1px solid #dde1ea;
      box-sizing: border-box;
      padding: 0;
      display: flex;
      overflow: scroll;
      width: 100%;
      align-items: baseline;
      padding-bottom: 0 !important;
      margin-bottom: 43px;
      position: inherit !important;
      &::-webkit-scrollbar {
        width: 0;
      }
      p {
        white-space: nowrap;
        margin-right: 20px;
        border-bottom: none;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
      }
    }
    .question_title {
      color: #8b91a9;
    }
    .question_title2 {
      font-size: var(--font-size18);
      line-height: 21px;
      white-space: nowrap;
    }
    .question_content {
      flex-direction: column;
      margin-top: 32px;
    }
    .right_content {
      margin-left: 0;
      width: 100% !important;
    }
  }
  .question_dropdown_item{
    display: flex;
    flex-direction: row-reverse;
    .dropdown_txt{
      margin-left: 20px;
    }
}
}
