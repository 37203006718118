.ads-slider {
    .slick-arrow {
        display: none !important;
    }
    .slick-slide {
        overflow: hidden !important;
    }

    .slick-dots {
        bottom: 20px;
        left: 0;
        top: auto !important;
        right: auto !important;
        width: auto !important;
    }
}