.about {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
   }

  &__img {
    width: 500px;
    height: 400px;
    object-fit: cover;
    position: sticky;
    top: 80px;

    @media only screen and (max-width: 768px) {
      position: static;
      width: 100%;
      height: 210px;
      margin-bottom: 33px;
     }
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
     }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__right {
    max-width: 500px;
  }

  &__wrapper {
    &:not(:last-child) {
      margin-bottom: 95px;
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size24);
    line-height: 30px;
    color: #1d213f;
    margin-bottom: 34px;
  }
}
