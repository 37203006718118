.requisites__tableDataRow {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  padding-top: 34px;
}

.requisites__tableDataRow:not(:last-child) {
  border-bottom: 1px solid #dde1ea;
  padding-bottom: 35px;
}

.requisites__tableid {
  font-size: var(--font-size14);
  line-height: 21px;
  color: #8b91a9;
  margin-right: 22px;
}

.requisites__tabledata {
  width: 50%;
  font-size: var(--font-size16);
  line-height: 21px;
  color: #1d213f;
}

.requisites__big:first-child {
  width: 50%;
}

.requisites__big:not(:first-child) {
  width: 20%;
}
