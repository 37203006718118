.agency__organization {
  background-color: #f1f2f5;
  padding: 26px 0 100px 0;

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    background-color: #fff;
    padding: 63px 70px 70px 70px;
    margin-top: 26px;

    @media only screen and (max-width: 768px) {
      padding: 48px 20px 27px 20px;
      margin-bottom: 82px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size36);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 50px;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 28px;
     }
  }

  &__wrapper {
    background: #f1f2f5;
    border: 1px solid #f1f2f5;
    box-sizing: border-box;
    padding: 51px 50px 80px 140px;
    position: relative;
    margin-bottom: 107px;

    @media only screen and (max-width: 768px) {
      padding: 73px 20px 40px 20px;
      margin-bottom: 87px;
     }

    &::before {
      content: "";
      width: 27px;
      height: 20px;
      position: absolute;
      top: 50px;
      border-radius: 50%;
      left: 50px;
      background-repeat: no-repeat;
      background-position: center;
      // background-size: 27px 20px;
      background-color: #fff;
      padding: 20px 17px;
      margin: auto;
      background-image: url(../../../assets/icons/quote.svg);

      @media only screen and (max-width: 768px) {
        background-color: transparent;
        top: 30px;
        left: 20px;
        height: 20px;
        background-size: contain;
        padding: 0;
        border-radius: 0;
       }
    }
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
     }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size24);
    line-height: 30px;
    color: #1d213f;
    max-width: 822px;
    margin-bottom: 53px;

    @media only screen and (max-width: 768px) {
      margin-bottom: 33px;
     }
  }

  .agency__organization__table__mobile {
    display: none;

    @media only screen and (max-width: 968px) {
      display: flex;
      flex-direction: column;
      padding-bottom: 45px;
      border-bottom: 1px solid #DDE1EA;
    }

  }

  &__mobile__wrapper {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__mobile__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size16);
    line-height: 30px;
    color: #1D213F;
    margin-bottom: 2px;
  }

  &__mobile__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 25px;
    color: #1D213F;
    text-decoration: none;
    display: block;
  }

  &__mobile__text {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 25px;
    color: #8B91A9;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    @media only screen and (max-width: 968px) {
      display: none;
     }

    thead {
      background: #f1f2f5;
      margin-bottom: 34px;

      td {
        width: 25% !important;
        padding: 25px 30px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size16);
        line-height: 21px;
        color: #1d213f;
      }
    }
  }

  &__bottom {
    padding-top: 59px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media only screen and (max-width: 968px) {
      padding: 0 16px;
      padding-top: 59px;
      flex-direction: column;
    }
  }

  &__holder {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 968px) {
      margin-bottom: 43px;
    }
  }

  &__img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    object-fit: cover;
  }

  &__unique {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 18px;
    color: #8b91a9;
    margin-bottom: 5px;
  }

  &__link {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 21px;
    display: block;
    color: #0065dd;
  }
}
