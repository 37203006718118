@mixin spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin containerWidth {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

@mixin noRepeat {
  background-repeat: no-repeat;
  width: 100%;
}

@mixin btnStyleNone {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

// slider part

.slider_top {
  @include spaceBetween;
  border-bottom: 1px solid grey;
  padding-bottom: 30px;

  .slide_txt {
    width: 70%;
    font-weight: 500;
    font-size: var(--font-size30);
    line-height: 35px;
  }

  .slide_top_inner_wrap {
    @include spaceBetween;
    width: 24% !important;

    a {
      text-decoration: none;
      font-size: var(--font-size13);
      line-height: 15px;
      text-transform: uppercase;
      color: #0065dd;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
    }

    .btn_wrapper {
      @include spaceBetween;
      // width: 30%;

      button {
        @include btnStyleNone;
      }
    }
  }
}

.card_wrapper {
  flex-wrap: wrap;
  margin-top: 35px;
  width: 100%;
  @include spaceBetween;

  .card_item {
    width: 98% !important;
    // height: 100px;
    background: rgba(255, 255, 255, 0.067);
    padding: 20px 16px 19px 21px;
    cursor: pointer;
    transition: all ease 0.2s;
    box-sizing: border-box;

    &:hover {
      box-shadow: 0px 10px 50px rgba(144, 158, 187, 0.2);
    }

    // &:nth-child(n + 1) {
    //   margin-right: 20px;
    // }

    .flex_txt {
      display: flex;
      align-items: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #a0a8b8;
      img {
        margin-right: 10px;
        height: 16px;
        width: 22px;
      }
    }

    .card_item_lightTxt {
      margin-bottom: 10px;
      font-weight: normal;
      font-size: var(--font-size14);
      line-height: 18px;
    }

    .card_item_boldTxt {
      margin-bottom: 15px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size16);
      line-height: 21px;
      color: #1a1a1a;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .slide_top_inner_wrap {
    width: 30% !important;
  }
  .slider_top {
    padding-bottom: 25px;
    margin: 0 20px;
  }
}

@media only screen and (max-width: 900px) {
  .slide_top_inner_wrap {
    width: 100px !important;
  }
  .slide_top_inner_wrap a {
    display: none;
  }
  .slider_top {
    padding-bottom: 25px;
    margin: 0 20px;
  }
  .btn_wrapper {
    width: 200px !important;
  }
  .slide_txt {
    font-size: var(--font-size24) !important;
  }
}

@media only screen and (max-width: 320px) {
  .slide_top_inner_wrap {
    width: 100px !important;
  }
  .slide_top_inner_wrap a {
    display: none;
  }
  .slider_top {
    padding-bottom: 25px;
    margin: 0 20px;
  }
  .btn_wrapper {
    width: 200px !important;
  }
  .slide_txt {
    font-size: var(--font-size24) !important;
  }
}
