.InputHeader {
  background: #f1f2f5;
  .InputHeader_input {
    margin-top: 30px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
    input {
      width: 96%;
      border: 0px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size18);
      line-height: 21px;
      color: #909ebb;
      background: #ffffff;
      padding-top: 19px;
      padding-bottom: 20px;
      padding-left: 25px;
      padding-right: 25px;
    }
    input:focus {
      outline: none;
    }
    img {
      position: absolute;
      right: 15px;
      top: 20px;
      cursor: pointer;
    }
  }
  .InputHeader_back {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 300px;
    bottom: 0;
    background: #1d213f;
    opacity: 0.9;
    z-index: 9;
  }
}
