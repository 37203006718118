.activity__statistics {
  background: #f1f2f5;
  padding: 26px 0 100px 0;

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    padding: 65px 70px;
    background-color: #fff;
    margin-top: 26px;

    @media only screen and (max-width: 768px) {
      padding: 48px 20px 27px 20px;
      margin-bottom: 87px;
      overflow-x: hidden;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    margin-bottom: 48px;
    color: #1d213f;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size24);
      line-height: 28px;
      margin-bottom: 28px;
     }
  }
}
