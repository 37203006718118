.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__item, a {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 18px;
    color: #8b91a9;
    list-style-type: none;
    position: relative;

    &:not(:last-child) {
      padding-right: 35px;
      &::after {
        content: "";
        width: 15px;
        height: 2px;
        background-color: #909ebb;
        position: absolute;
        right: 10px;
        bottom: 0;
        top: 0;
        margin: auto;
      }
    }
  }
}
