.RegulationsInternational_container_items{
    padding-bottom: 0;
    .regulations_container__items__item{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .item_card{
            margin-bottom: 20px;
            width: 45%;
        }
    }

    &__link {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size18);
        line-height: 21px;
        color: #0065dd;
        text-decoration: none;
        display: block;
        padding: 23px 0;
        position: relative;
        transition: all ease 0.4s;
    
        @media only screen and (max-width: 768px) {
          font-size: var(--font-size16);
          padding-right: 40px;
         }
    
        &::after {
          content: "";
          width: 0;
          height: 0;
          overflow: hidden;
          transition: all ease 0.2s;
        }
    
        &:hover {
          box-shadow: 0px 10px 50px rgba(144, 158, 187, 0.2);
          &::after {
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 30px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(../../../../assets/images/right-arr.png);
          }
        }
    
        &:not(:last-child) {
          border-bottom: 1px solid #dde1ea;
        }
    
        h4 {
          font-weight: normal;
          font-family: "Roboto";
          max-width: 738px;
        }
      }

    @media only screen and (max-width: 768px) {
        a {
            margin-bottom: 20px;
        }
    }

    .regulations_container__items__item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media only screen and (max-width: 768px) {
            margin-bottom: 0;
        }

        div {
            background-color: #fff;
            width: 50%;
            margin-bottom: 0;
            border:0px;
            // padding-top: 15px;
            // padding-bottom: 15px;
            // &:nth-last-child(-n+3) {
            //     margin-bottom: 0;
            // }
            &:hover{
            border: 0;
            }
        }
    }
    
    // &:nth-last-child(1){
    //     border-bottom: 0px;
    // }
}