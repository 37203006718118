.ActivityAppeal {
  padding-top: 35px;
  padding-bottom: 100px;
  background: #f1f2f5;
  .ActivityAppeal_item {
    margin-top: 35px;
    max-width: 1070px;
    width: 100%;
    background: #ffffff;
    margin-left: auto;
    margin-right: auto;
    padding: 63px 70px 69px 70px;
    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size40);
      line-height: 47px;
      color: #1d213f;
      margin-bottom: 51px;
    }
    .ActivityAppeal_item_header {
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size24);
        line-height: 21px;
        color: #000000;
        margin-bottom: 38px;
      }
    }
    .Vacency_main_middle_right_bottom {
      margin-top: 55px;
      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size14);
        line-height: 21px;
        color: #8b91a9;
        width: 217px;
      }
      .Vacency_main_middle_right_bottom_child {
        display: flex;
        align-items: center;
        img {
          margin-right: 15px;
        }
        span {
          margin-top: 24px;
          a {
            text-decoration: none;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: var(--font-size14);
            line-height: 21px;
            color: #1d213f;
            margin-left: 30px;
            margin-left: 0;
          }
        }
      }
    }
  }
}
@media only screen and (max-width:500px) {
  .ActivityAppeal .ActivityAppeal_item h1{
    font-size: var(--font-size24);
    line-height: 30px;
  }
  .ActivityAppeal .ActivityAppeal_item .ActivityAppeal_item_header h1{
    font-size: var(--font-size18) !important;
    background: #F1F2F5 !important;
    padding: 18px 20px 14px 20px ;

  }
  .ActivityAppeal .ActivityAppeal_item{
    padding-left:20px ;
    padding-right: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
  
}
