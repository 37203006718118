.InformationServiseContests {
  background: #f1f2f5;
  padding-top: 26px;
  padding-bottom: 100px;
  .InformationServiseContests_inner {
    background: #ffffff;
    margin-top: 26px;
    padding: 63px 70px 68px 70px;
    .InformationServiseContests_inner_title {
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size40);
        line-height: 47px;
        color: #1d213f;
        margin-bottom: 50px;
      }
    }
    .InformationServiseContests_inner_main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .InformationServiseContests_inner_main_item {
        text-decoration: none;
        width: 48%;
        h2 {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size14);
          line-height: 18px;
          color: #1d213f;
          margin-bottom: 10px;
        }
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: var(--font-size18);
          line-height: 24px;
          color: #1d213f;
          margin-bottom: 58px;
          width: 60%;
        }
        img {
          margin-bottom: 26px;
          max-height: 300px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
@media only screen and(max-width:1200px) {
  .InformationServiseContests
    .InformationServiseContests_inner
    .InformationServiseContests_inner_main
    .InformationServiseContests_inner_main_item {
    width: auto;
  }
  .InformationServiseContests
    .InformationServiseContests_inner
    .InformationServiseContests_inner_main {
    justify-content: center !important;
  }
  .InformationServiseContests
    .InformationServiseContests_inner
    .InformationServiseContests_inner_main
    .InformationServiseContests_inner_main_item
    img {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .InformationServiseContests
    .InformationServiseContests_inner
    .InformationServiseContests_inner_title
    h1 {
    font-size: var(--font-size24);
    line-height: 30px;
    margin-bottom: 22px;
  }
  .InformationServiseContests .InformationServiseContests_inner {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 47px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .InformationServiseContests
    .InformationServiseContests_inner
    .InformationServiseContests_inner_main
    .InformationServiseContests_inner_main_item
    p {
    font-size: var(--font-size16);
    line-height: 21px;
    width: 100%;
    margin-bottom: 39px;
  }
}
