.BoardAppeal_bottom_main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  .BoardAppeal_bottom_main_item {
    max-width: 570px;
    width: 100%;
    margin-left: 15px;
    margin-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 20px 50px rgba(144, 158, 187, 0.2);
    .BoardAppeal_bottom_main_item_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 35px;
      cursor: pointer;
      span {
        max-width: 300px;
        width: 100%;
        margin-top: 28px;
        margin-left: 30px;
        h2 {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: var(--font-size18);
          line-height: 24px;
          color: #1d213f;
        }
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size16);
          line-height: 21px;
          color: #8b91a9;
        }
      }
      .btn {
        width: 30px;
        height: 30px;
        margin-right: 30px;
        border: 1.5px solid #0065dd;
        background: #ffffff;
        box-sizing: border-box;
        margin-top: 20px;
        cursor: pointer;
        background-image: url("../../assets/icons/ICON\ \(2\).png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
      }
    }
    .BoardAppeal_bottom_main_item_elem {
      .BoardAppeal_bottom_main_item_elem_child {
        position: relative;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        padding-top: 36px;
        padding-bottom: 36px;
        span {
          max-width: 428px;
          width: 100%;
          h4 {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: var(--font-size16);
            line-height: 21px;
            color: #1d213f;
            margin-bottom: 24px;
          }
          h5 {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: var(--font-size16);
            line-height: 21px;
            color: #8b91a9;
          }
        }
      }
      .BoardAppeal_bottom_main_item_elem_child::after {
        content: "";
        position: absolute;
        max-width: 509px;
        width: 100%;
        border: 1px solid #dde1ea;
        top: 0;
      }
    }
  }
}
.block {
  display: none;
}
.btn_click {
  background: #0065dd;
  width: 30px;
  height: 30px;
  margin-right: 30px;
  border: 1.5px solid #0065dd;
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;
  background-image: url("../../assets/icons/ICON\ \(1\).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}
@media only screen and (max-width:500px) {
  .BoardAppeal_bottom .BoardAppeal_bottom_title h1 {
    font-size: var(--font-size24);
}
.BoardAppeal_bottom_main .BoardAppeal_bottom_main_item .BoardAppeal_bottom_main_item_header span h2 {
  font-size: var(--font-size16);
}  
.BoardAppeal_bottom_main .BoardAppeal_bottom_main_item{
  margin-left: 0;
}
}
