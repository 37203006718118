.patent__fund__item {
  td {
    padding: 35px 30px 0 30px;
    box-sizing: border-box;
  }

  .patent__fund__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 21px;
    color: #1d213f;
    margin-bottom: 5px;
  }

  .patent__fund__helpertext {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 26px;
    color: #8b91a9;
  }
}
