.patent__fund {
  background: #f1f2f5;
  padding: 26px 0 100px 0;

  @media only screen and (max-width: 968px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    background-color: #fff;
    padding: 63px 70px 65px 70px;
    box-sizing: border-box;
    margin-top: 26px;

    @media only screen and (max-width: 968px) {
      padding: 48px 20px 27px 20px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 50px;

    @media only screen and (max-width: 968px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 36px;
     }
  }

  &__img {
    position: sticky;
    top: 80px;

    @media only screen and (max-width: 968px) {
      position: static;
      width: 100%;
      object-fit: cover;
      height: 210px;
      margin-bottom: 41px;
     }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;

    @media only screen and (max-width: 968px) {
      flex-direction: column;
      margin-bottom: 52px;
     }
  }

  &__text {
    max-width: 500px;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    @media only screen and (max-width: 968px) {
      font-size: var(--font-size16);
      max-width: 100%;
     }
  }

  &__mobile {
    display: none;

    @media only screen and (max-width: 768px) {
      display: block;
    }

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size16);
      line-height: 24px;
      color: #1D213F;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size16);
      line-height: 24px;
      color: #1D213F;
    }

    &__text {
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #8B91A9;
    }

    &__wrapper {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    @media only screen and (max-width: 768px) {
      display: none;
     }
    thead {
      background: #f1f2f5;
      box-sizing: border-box;
      .patent__fund__thead {
        font-style: normal;
        padding: 25px 30px !important;
        font-weight: 500;
        font-size: var(--font-size16);
        line-height: 21px;
        color: #1d213f;
      }
    }

    tbody {
      td:first-child {
        width: 22%;
        text-align: center;
      }

      td {
        text-align: center;
        width: 30%;
      }
    }
  }
}
