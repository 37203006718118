.ActivityArbitrageTable {
  h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size24);
    line-height: 21px;
    color: #1d213f;
    margin-bottom: 38px;
  }
  .ActivityArbitrageTable_item {
    table {
      width: 100%;
      border-collapse: collapse;
      tr {
        border-bottom: 1px solid #dde1ea;
        th {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: var(--font-size14);
          line-height: 21px;
          color: #1d213f;
          padding: 25px 0 24px 0;
          background-color: #f1f2f5;
          width: 110px;
          text-align: center;
          border-radius: 0;
        }
        td {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size16);
          line-height: 21px;
          color: #1d213f;
          text-align: center;
          padding: 25px 0 24px 0;
        }
      }
      tr:nth-last-child(1) {
        border: 0;
      }
      tr:first-child {
        border: 0;
      }
    }
  }
}
.itemss {
  width: 307px;
  background: #ffffff;
  box-shadow: 0px 20px 50px rgba(144, 158, 187, 0.2);
  display: flex;
  align-items: center;
  cursor: pointer;
  h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 21px;
    color: #1d213f;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 21px;
    color: #8b91a9;
  }
}
.left{
  text-align: left !important;
  padding-left: 30px !important;
}
span{
  .mediatab{
    display: none;
  }
}
span:nth-child(1){
  .mediatab:nth-child(1){
    display: block;
    border: 0 !important;
  }
}
.ActivityArbitrageTable:nth-child(2){
  display: none ;
}
@media only screen and (max-width:1200px) {
  .laptop{
    width: 750px !important;
    overflow-x: scroll;
  }
  .ActivityArbitrageTable_item{
    overflow-x:scroll ;
    &::-webkit-scrollbar{
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: #F1F2F7; 
    }
    &::-webkit-scrollbar-track {
      width: 8px;
    }
  }
}
@media only screen and (max-width:500px) {
  .ActivityArbitrageTable  h1{
    background-color: #F1F2F5;
    padding: 20px 14px;
    font-size: var(--font-size18);
  }
  .ActivityArbitrageTable:nth-child(1){
    display: none;
  }
  .ActivityArbitrageTable:nth-child(2){
    display: block;
  }
  .mobaile{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    tr{
      padding-bottom: 15px;
      padding-top: 30px;
      border-bottom: 1px solid #DDE1EA;
      &:nth-last-child(1){
        border: 0;
      }
      p{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #1D213F;
        margin-bottom: 15px;
        span{
          font-weight: 400;
          margin-left: 3px;
        }
      }
    }
  }
}