.Patents {
  border: 1px solid #dde1ea;
  box-sizing: border-box;
  margin-top: 30px;
  .Patents_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 30px;
    border-bottom: 1px solid #dde1ea;
    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size16);
      line-height: 24px;
      color: #1d213f;
    }
    .btn_all {
      border: 1.5px solid #0065dd;
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      background: none;
      cursor: pointer;
      background-image: url("../../assets/icons/ICON\ \(2\).png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
    }
    .btn_alls {
      border: 1.5px solid #0065dd;
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      background: #0065dd;
      cursor: pointer;
      background-image: url("../../assets/icons/ICON\ \(1\).png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
    }
  }
  .Patents_details {
    padding-top: 25px;
    padding-left: 30px;
    display: block;
    .Patents_details_info {
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size16);
        line-height: 30px;
        color: #1d213f;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img {
          margin-right: 14px;
          margin-bottom: 10px;
        }
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size16);
        line-height: 30px;
        color: #1d213f;
        display: flex;
        align-items: flex-start;
        margin-top: 13px;
        span {
          color: blue;
          font-size: 16px;
          margin-top: 1px;
          margin-left: 5px;
        }
      }
    }
    .Patents_details_contact {
      .Patents_details_contact_title {
        h1 {
          margin-top: 50px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size16);
          line-height: 30px;
          color: #8b91a9;
        }
      }
      .Patents_details_contact_item {
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        justify-content: flex-start;
        .Patents_details_contact_item_inner {
          display: flex;
          align-items: flex-start;
          margin-right: 73px;
          margin-bottom: 22px;
          a {
            text-decoration: none;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: var(--font-size14);
            line-height: 24px;
            color: #8b91a9;
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            span {
              color: #1d213f;
            }
          }
        }
      }
    }
  }
}
.open {
  display: none;
}

@media only screen and (max-width:500px) {

  .Patents .Patents_details{
    padding-left: 10px;
  }
  
}