.public__council {
  background: #f1f2f5;
  padding: 36px 0 100px 0;

  @media only screen and (max-width: 968px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    background-color: #fff;
    padding: 73px 70px 60px 70px;
    box-sizing: border-box;
    margin-top: 16px;

    @media only screen and (max-width: 968px) {
      padding: 48px 20px 27px 20px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 48px;

    @media only screen and (max-width: 968px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 17px;
     }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media only screen and (max-width: 968px) {
      flex-direction: column;
      overflow: auto;
    }
  }

  &__links {
    position: sticky;
    top: 85px;

    @media only screen and (max-width: 968px) {
      position: static;
      overflow: auto;
      width: 100%;

      &::-webkit-scrollbar {
        width: 0;
       }
    }
  }

  &__holderr {
    border: 1px solid #dde1ea;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 30px;

    @media only screen and (max-width: 968px) {
      position: static;
      flex-direction: row;
      width: 1500px;
      justify-content: space-between;
      align-items: center;
      border: none;
      overflow-x: scroll;
      padding: 0;

      &::-webkit-scrollbar {
        width: 0;
       }
    }
  }

  &__link {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 21px;
    color: #0065dd;
    text-decoration: none;
    display: block;
    max-width: 234px;
    padding: 26px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #dde1ea;
    }

    
    @media only screen and (max-width: 968px) {
      color: #000;
      padding: 17px 0;
      max-width: 100%;
      &:first-child {
        border-bottom: 2px solid #0065dd;
      }
    }
  }

  &__subtitle {
    margin-bottom: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size24);
    line-height: 28px;
    color: #1d213f;
    max-width: 440px;

    @media only screen and (max-width: 968px) {
      font-size: var(--font-size18);
    }
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;
    max-width: 630px;
  }

  &__right {
    width: 60%;

    @media only screen and (max-width: 968px) {
      width: 100%;
    }
  }

  li {
    max-width: 600px;
    list-style-type: none;
  }

  &__holder {
    &:not(:last-child) {
      margin-bottom: 100px;

      @media only screen and (max-width: 968px) {
        margin-bottom: 40px;
      }
    }
  }

  &__info {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #8b91a9;
    margin-top: 27px;
    max-width: 630px;
  }
}
