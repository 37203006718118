.ActivityPatent {
  background: #f1f2f5;
  padding-top: 35px;
  padding-bottom: 100px;
  .ActivityPatent_inner {
    margin-top: 35px;
    background: #ffffff;
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 63px;
    .ActivityPatent_inner_title {
      padding-top: 60px;
      padding-left: 70px;
      padding-right: 70px;
      margin-bottom: 20px;
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size40);
        line-height: 47px;
        color: #1d213f;
      }
    }
    .ActivityPatent_inner_main {
      padding-left: 70px;
      padding-right: 70px;
      .ActivityPatent_inner_main_item {
        width: 100%;
        margin-right: 10px;
      }
    }
  }
}
@media only screen and (max-width:640px) {
  .ActivityPatent .ActivityPatent_inner .ActivityPatent_inner_main{
    flex-direction: column;
    justify-content: center !important;
  }
  .ActivityPatent .ActivityPatent_inner .ActivityPatent_inner_title{
    h1{
      font-size: var(--font-size24) !important;
      line-height: 30px;
  
    }
    img{
      display: none;
    }
  }
}
@media only screen and (max-width:500px) {
  .ActivityPatent .ActivityPatent_inner .ActivityPatent_inner_main{
    padding-left: 20px;
    padding-right: 20px;
  }
  .ActivityPatent .ActivityPatent_inner .ActivityPatent_inner_title{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 48px;
    margin-bottom: 0;
  }
  .ActivityPatent .ActivityPatent_inner{
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
  
}
