.service__public {
  display: flex;

  &__link {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 24px;
    display: block;
    text-decoration: none;
    color: #0065dd;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__left {
    margin-right: 100px;
  }
}
