.duties {
  background: #f1f2f5;
  padding: 26px 0 100px 0;

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    background-color: #fff;
    padding: 65px 70px;
    margin-top: 26px;

    @media only screen and (max-width: 768px) {
      padding: 48px 20px 27px 20px;
      margin-bottom: 87px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 54px;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size24);
      line-height: 28px;
      margin-bottom: 28px;
     }
  }

  &__link {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 24px;
    color: #0065dd;
    display: block;
    max-width: 815px;
    text-decoration: none;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
      padding: 15px 10px;
      padding-right: 40px;
      &:hover {
        text-decoration: underline;
      }
     }
  }
}
