.ServicesProgramsApplication{
    h2{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #0065DD;
        margin-bottom: 12px;
        margin-top: 50px;
    }
    p{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 16px;
    }
    ul{
        list-style: none;
        li{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #1D213F;
            margin-bottom: 19px; 
        }
    }
}