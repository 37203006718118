.activity__intellectual {
  background: #f1f2f5;
  padding: 26px 0 100px 0;

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    background-color: #fff;
    padding: 63px 70px;
    box-sizing: border-box;
    margin-top: 26px;
    margin-bottom: 107px;

    @media only screen and (max-width: 768px) {
      padding: 48px 40px 27px 20px;
      margin-bottom: 87px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 50px;

    @media only screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 28px;
     }
  }

  &__wrapper {
    background: #f1f2f5;
    border: 1px solid #f1f2f5;
    box-sizing: border-box;
    padding: 34px 120px;
    position: relative;
    margin-bottom: 107px;
    margin-bottom: 41px;

    @media only screen and (max-width: 768px) {
      padding: 90px 20px 37px 30px;
     }

    &::before {
      content: "";
      width: 27px;
      height: 20px;
      position: absolute;
      top: 0;
      border-radius: 50%;
      left: 30px;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      // background-size: 27px 20px;
      background-color: #fff;
      padding: 20px 17px;
      margin: auto;
      background-image: url(../../../assets/icons/quote.svg);

      @media only screen and (max-width: 768px) {
        background-color: transparent;
        top: 30px;
        left: 20px;
        bottom: auto;
       }
    }
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
    }
  }

  &__link {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #0065dd;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
    }
  }

  &__left {
    max-width: 280px;
    margin-right: 200px;

    @media only screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 53px;
    }
  }

  &__helper__text {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 21px;
    color: #8b91a9;

    .activity__intellectual__link {
      font-size: var(--font-size16);
    }
  }

  &__intellectual {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size30);
    line-height: 35px;
    color: #1d213f;
    margin-bottom: 37px;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size24);
      margin-bottom: 16px;
      line-height: 30px;
    }
  }

  &__holder {
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
     }
  }

  &__helper__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size24);
    line-height: 30px;
    max-width: 430px;
    color: #1d213f;
    margin-bottom: 34px;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size18);
      line-height: 24px;
    }
  }

  &__right {
    max-width: 600px;

    .activity__intellectual__block:not(:last-child) {
      margin-bottom: 77px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-top: 95px;

    .activity__intellectual__link {
      font-size: var(--font-size16);
      display: block;
    }
  }

  &__img {
    margin-right: 20px;

    @media only screen and (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
  }
}
