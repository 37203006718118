.agency__structure {
  background: #f1f2f5;
  padding: 26px 0 94px 0;

  @media only screen and (max-width: 1200px) {
    padding: 26px 20px 80px 20px;
  }
}

.structura {
  background-color: #fff;
  padding: 60px 70px 76px;

  @media only screen and (max-width: 1200px) {
    padding: 48px 20px 27px 20px;
    margin-bottom: 82px;
  }
}

.agency__structure__inner {
  background-color: #fff;
  margin-top: 32px;
}

.structura__title {
  font-weight: 500;
  font-size: var(--font-size40);
  line-height: 47px;
  color: #1d213f;
  margin-bottom: 50px;

  @media only screen and (max-width: 1200px) {
    font-size: var(--font-size24);
    line-height: 28px;
    margin-bottom: 28px;
   }
}

.agency__mobile__img {
    display: none;

    @media only screen and (max-width: 1200px) {
        display: block;
       }
}

.structura__tree {
  width: 1030px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // background-image: url(../../../assets/images/structure.jpg);
  background-repeat: no-repeat;
  background-position: calc(50% - 78px) calc(50% - 21px);

  @media only screen and (max-width: 1200px) {
      display: none;
   }
}

.structura__row {
  // width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.structura__element {
  font-size: var(--font-size18);
  width: 320px;
  height: 100px;
  background: #f1f2f5;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 44px;
  box-sizing: border-box;
  text-align: center;
}

.structura__border {
  text-align: center;
  width: 320px;
  height: 100px;
  border: 4px solid #f1f2f5;
  box-sizing: border-box;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 44px;
}
