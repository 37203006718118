.slider_new {
  width: 100%;
  font-size: var(--font-sizerem);
  padding-bottom: 95px;
  border-bottom: 1px solid #dde1ea;
  background-color: #fff;
}
.gallery {
  perspective: 1200px;
}
.spinner {
  transform-style: preserve-3d;
  min-height: 122px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50% -708px;
  transition: 1s;
  margin-bottom: 200px;
  margin-top: 50px;
}
.txt_flex {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 36px;
}
.slide_txt6 {
  font-weight: 500;
  font-size: var(--font-size30);
  line-height: 35px;
  color: #bcc5d6;
  text-decoration: none;
  border-bottom: 0;
  cursor: pointer;
}
.spinner div {
  left: 30%;
  object-fit: cover;
  transform-origin: 50% 50% -678px;
  outline: 1px solid transparent;
  img {
    width: 50%;
    position: absolute;
    left: 26%;
    object-fit: cover;
    transform-origin: 50% 50% -500px;
    outline: 1px solid transparent;
  }
  p{
    position: absolute;
    bottom: 0;
    left: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #FFFFFF;

  }
}
.float {
  float: left;
  color: black;
}
.float2 {
  float: right;
  color: black;
}
.slider_top {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 53px;
  padding-top: 30px;
}

.slide_top_inner_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 24%;
  margin-top: -33px;
  margin-left: auto;
  .viewAllClass {
    text-decoration: none;
    font-size: var(--font-size13);
    line-height: 15px;
    text-transform: uppercase;
    color: #0065dd;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
  }
}
.btn_wrapper {
  display: flex;
  width: 30%;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }
}

// round carusel that will be displayed in responsive mode
.round_single_card {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .slide_txt6 {
    margin-right: 30px;
  }
  .txt_flex {
    width: 70%;
    justify-content: normal;
    width: 50%;
  }
}

@media only screen and (max-width: 900px) {
  .slide_top_inner_wrap {
    display: none;
    // justify-content: center;
    // a {
    //   display: none;
    // }
  }

  .txt_flex {
    width: 70%;
  }
}
@media only screen and (max-width: 750px) {
  .slide_top_inner_wrap {
    display: none;
  }
  .slider_top {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .slide_txt6 {
    margin-right: 20px;
    white-space: nowrap;
  }
  .txt_flex {
    width: 85%;
  }
  .slide_txt6 {
    margin-right: 40px;
    font-size: var(--font-size20);
  }
}
@media only screen and (max-width: 700px) {
  .news_wrapper {
    position: relative;
  }
  .slide_top_inner_wrap {
    display: block;
    position: absolute;
    top: 550px;
    right: 20px;
    .viewAllClass {
      display: none;
    }
  }

  .slide_txt6 {
    margin-right: 40px;
    font-size: var(--font-size20);
  }
  .txt_flex {
    width: 100%;
  }
  .gallery {
    display: none;
  }
  .round_single_card {
    width: 100%;
    display: block;
  }
  .slider_top {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 420px) {
  .news_wrapper {
    position: relative;
  }
  .slider_top {
    margin-bottom: 0;
  }
  .slide_top_inner_wrap {
    display: block;
    position: absolute;
    top: 550px;
    left: 170px;

    .viewAllClass {
      margin-top: 20px !important;
      display: block !important;
      margin-left: 0 auto !important;
      position: absolute !important;
      width: 300px !important;
      height: 15px !important;
      padding: 15px !important;
      border: 1px solid blue !important;
      text-align: center !important;
      top: 60px !important;
      white-space: nowrap !important;
      text-align: center !important;
      left: -279% !important;
    }
  }
  .txt_flex {
    overflow: scroll;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .slide_txt6 {
    font-size: var(--font-size16);
    margin-right: 30px;
  }
  .gallery {
    display: none;
  }
  .round_single_card {
    display: block;
  }
}

@media only screen and (min-width: 320px) and (max-width: 380px) {
  .slide_top_inner_wrap {
    display: block;
    position: absolute;
    top: 550px;
    left: 170px;

    .viewAllClass {
      margin-top: 20px !important;
      display: block !important;
      margin-left: 0 auto !important;
      position: absolute !important;
      width: 225px !important;
      height: 15px !important;
      padding: 15px !important;
      border: 1px solid blue !important;
      text-align: center !important;
      top: 60px !important;
      white-space: nowrap !important;
      text-align: center !important;
      left: -279% !important;
    }
  }
}

@media only screen and (max-width: 320px) {
  .news_wrapper {
    position: relative;
  }
  .slider_top {
    margin-bottom: 0;
  }
  .slide_top_inner_wrap {
    display: block;
    position: absolute;
    top: 550px;
    left: 170px;

    .viewAllClass {
      margin-top: 20px !important;
      display: block !important;
      margin-left: 0 auto !important;
      position: absolute !important;
      width: 225px !important;
      height: 15px !important;
      padding: 15px !important;
      border: 1px solid blue !important;
      text-align: center !important;
      top: 60px !important;
      white-space: nowrap !important;
      text-align: center !important;
      left: -279% !important;
    }
  }
  .txt_flex {
    overflow: scroll;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .slide_txt6 {
    font-size: var(--font-size14);
    margin-right: 30px;
  }
  .gallery {
    display: none;
  }
  .round_single_card {
    display: block;
  }
  .viewAllClass {
    display: block !important;
    transform: translate(10px 10px) !important;
    color: yellow;
  }
}
