.activity__government {
  background: #f1f2f5;
  padding: 26px 0 100px 0;

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    background-color: #fff;
    margin-top: 26px;
    padding: 63px 70px;

    @media only screen and (max-width: 768px) {
      padding: 48px 20px 27px 20px;
      margin-bottom: 87px;
      overflow-x: hidden;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    margin-bottom: 50px;
    color: #1d213f;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size24);
      line-height: 28px;
      margin-bottom: 28px;
     }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
     }
  }

  &__img {
    width: 500px;
    height: 400px;
    object-fit: cover;
    position: sticky;
    top: 80px;

    @media only screen and (max-width: 768px) {
      position: static;
      width: 100%;
      height: 200px;
      margin-bottom: 33px;
     }
  }

  &__right {
    max-width: 500px;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
     }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}
