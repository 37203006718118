.chart {
  margin-bottom: 94px;
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size24);
    line-height: 21px;
    color: #1d213f;
    margin-bottom: 43px;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size18);
      margin-bottom: 63px;
     }
  }
}
