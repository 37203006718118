.single__news {
  background: #f1f2f5;
  padding: 26px 0 100px 0;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 50px 20px;
  }

  &__inner {
    background-color: #fff;
    padding: 63px 70px;
    box-sizing: border-box;
    margin-top: 26px;
    margin-bottom: 103px;

    @media only screen and (max-width: 768px) {
      padding: 48px 40px 27px 20px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 30px;
    max-width: 777px;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size24);
      line-height: 28px;
      margin-bottom: 18px;
    }
  }

  &__date {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    margin-bottom: 53px;
    color: #8b91a9;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size16);
      line-height: 19px;
      margin-bottom: 21px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    border-bottom: 1px solid #dde1ea;
    align-items: flex-start;

    @media only screen and (max-width: 968px) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__left {
    position: sticky;
    top: 80px;

    @media only screen and (max-width: 968px) {
      position: static;
      width: 100%;
      margin-bottom: 33px;
    }

    .single__news__img {
      @media only screen and (max-width: 968px) {
        width: auto !important;
      }
    }
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;
    max-width: 500px;

    @media only screen and (max-width: 968px) {
      font-size: var(--font-size16);
      line-height: 24px;
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 30px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 30px;
    
    .watchers__wrapper {
        @media only screen and (max-width: 1200px) {
          display: none !important;
        }
    }

    // @media only screen and (max-width: 1600px) {
    //   display: none;
    // }
  }

  &__author {
    display: flex;
    align-items: center;

    .watchers__wrapper {
      display: none !important;

      @media only screen and (max-width: 1200px) {
        display: flex !important;
      }
    }
  }

  &__author__img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    margin-right: 13px;
    object-fit: cover;
  }

  .author__name {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 18px;
    color: #8b91a9;

    @media only screen and (max-width: 768px) {
      margin-bottom: 8px;
    }
  }

  .single__news__social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size16);
      line-height: 18px;
      margin-right: 22px;
      color: #8b91a9;

      @media only screen and (max-width: 768px) {
        margin-right: 11px;
      }
    }
  }

  &__icon {
    width: 20px;
    border: 1px solid #bcc5d6;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:not(:last-child) {
      margin-right: 20px;
      @media only screen and (max-width: 574px) {
        margin-right: 15px;
      }
    }

    &::hover {
      border: 1px solid #0065dd;
    }

    &:hover {
      svg {
        fill: #0065dd;
      }
    }
  }

  .watchers__wrapper {
    display: flex;
    align-items: center;
  }

  .watchers {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 18px;
    margin-left: 10px;
    color: #bcc5d6;
  }

  .print {
    cursor: pointer;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  .news_item_wrapper {
    padding-bottom: 50px;
  }

  .news_item {
    box-sizing: border-box;
    width: 370px !important;
    margin-right: 20px;
    text-decoration: none;
    display: block;
    outline: none;

    .news_img {
      width: 100%;
      margin-bottom: 26px;
    }

    .margin_bottom {
      margin-bottom: 10px;
      font-size: var(--font-size14);
      line-height: 18px;
      color: #1d213f;
    }

    .news__text {
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size16);
      line-height: 21px;
      color: #1d213f;

      @media only screen and (max-width: 768px) {
       max-width: 300px;
      }
    }
  }

  .holder__wrapper__bottom {
    display: none;
    @media only screen and (max-width: 768px) {
      display: flex;
      margin-top: 34px;
      justify-content: flex-end;
     }
  }

  .holder {
    margin-bottom: 53px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .holder__wrapper {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    @media only screen and (max-width: 768px) {
      margin-bottom: 35px;
    }

    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size30);
      line-height: 35px;
      color: #1d213f;

      @media only screen and (max-width: 768px) {
        font-size: var(--font-size24);
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__btn {
      padding: 12px 13px;
      border: 1px solid #0065dd;
      box-sizing: border-box;
      background-color: transparent;
      cursor: pointer;

      outline: none;

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}