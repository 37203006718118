@mixin spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin btnStyleNone {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.single__ad {
    background: #f1f2f5;
    padding: 26px 0 100px 0;
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
        padding: 26px 20px 50px 20px;
    }

    &__inner {
        background-color: #fff;
        padding: 63px 70px;
        box-sizing: border-box;
        margin-top: 26px;
        margin-bottom: 60px;

        @media only screen and (max-width: 768px) {
            padding: 48px 40px 27px 20px;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size40);
        line-height: 47px;
        color: #1d213f;
        margin-bottom: 50px;
        max-width: 707px;

        @media only screen and (max-width: 768px) {
            font-size: var(--font-size24);
            line-height: 28px;
            margin-bottom: 18px;
        }
    }

    &__wrapper {
        display: flex;
        padding-bottom: 100px;
        border-bottom: 1px solid #dde1ea;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__text {
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size18);
        line-height: 26px;
        color: #1d213f;
        max-width: 570px;

        &:not(:last-child) {
            margin-bottom: 30px;

            @media only screen and (max-width: 768px) {
                margin-bottom: 15px;
            }
        }

        @media only screen and (max-width: 768px) {
            font-size: var(--font-size18);
        }
    }

    &__date {
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size16);
        line-height: 26px;
        color: #8b91a9;
        margin-right: 109px;

        @media only screen and (max-width: 768px) {
            margin-bottom: 29px;
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 30px;
    }

    &__author {
        display: flex;
        align-items: center;
    }

    &__author__img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: block;
        margin-right: 13px;
        object-fit: cover;
    }

    .author__name {
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size14);
        line-height: 18px;
        color: #8b91a9;
    }

    .single__ad__social {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        h4 {
            font-style: normal;
            font-weight: normal;
            font-size: var(--font-size16);
            line-height: 18px;
            margin-right: 22px;
            color: #8b91a9;
        }
    }

    &__icon {
        width: 20px;
        border: 1px solid #bcc5d6;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
            margin-right: 20px;
        }

        &::hover {
            border: 1px solid #0065dd;
        }

        &:hover {
            svg {
                fill: #0065dd;
            }
        }
    }

    .watchers__wrapper {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .watchers {
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size14);
        line-height: 18px;
        margin-left: 10px;
        color: #bcc5d6;
    }

    .print {
        cursor: pointer;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .slider_below {
        margin: 100px 0 0 0;

        .slider_top {
            @include spaceBetween;
            border-bottom: 1px solid grey;
            padding-bottom: 30px;

            .slide_txt {
                font-weight: 500;
                font-size: var(--font-size30);
                line-height: 35px;
            }

            .slide_top_inner_wrap {
                @media only screen and (max-width: 768px) {
                    .btn_wrapper {
                        display: none;
                    }
                }

                @include spaceBetween;

                a {
                    text-decoration: none;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: var(--font-size13);
                    line-height: 15px;
                    text-transform: uppercase;

                    color: #0065dd;
                }
            }

            .btn_wrapper {
                @include spaceBetween;
                width: 30%;

                button {
                    @include btnStyleNone;
                }
            }
        }
    }

    .card_wrapper {
        flex-wrap: wrap;
        margin-top: 35px;
        width: 100%;

        @media only screen and (max-width: 768px) {
            padding-bottom: 50px;
        }

        button {
            @include btnStyleNone;
        }

        .btn_wrapper {
            display: none;
            @media only screen and (max-width: 768px) {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-top: 34px;
            }
        }



        @include spaceBetween;

        .card_item {
            width: 90% !important;
            // height: 100px;
            padding: 20px 10px 19px 21px;
            background-color: #fff;
            overflow: hidden;
            cursor: pointer;

            &:hover {
                box-shadow: 0px 10px 100px rgba(144, 158, 187, 0.1);
            }

            // &:nth-child(n + 1) {
            //   margin-right: 20px;
            // }

            .flex_txt {
                display: flex;

                img {
                    margin-right: 10px;
                }
            }

            .card_item_lightTxt {
                margin-bottom: 10px;
                font-weight: normal;
                font-size: var(--font-size14);
                line-height: 18px;
            }

            .card_item_boldTxt {
                margin-bottom: 15px;
                font-weight: bold;
                font-size: var(--font-size16);
                line-height: 21px;
            }
        }
    }
}