.utility_model_blocks {
  margin-top: 20px;
  position: absolute;
}

.utility__models {
  padding-top: 30px;
  padding-bottom: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .utility__models_inner{
  background: #FFFFFF;
  width: 74%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-right: 30px;
  margin-top: 70px;
  }
  .utility__models_inner_top{
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    margin-left: 50px;
    margin-right: 50px;
    h1{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 45px;
      color: #1D213F;
    }
  .image{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 57px;
    background: #F1F2F5;
    width: 159px;
    height: 159px;
    border-radius: 50%;
  }
  }
  .utility__models_inner_menu{
    display: flex;
    overflow-x: auto;
    margin-left: 50px;                
    margin-right: 50px;
    margin-bottom: 48px;

    &::-webkit-scrollbar{
      height: 10px;
    }
    &::-webkit-scrollbar-thumb{
      background: #DDE1EA;
    }
    ul{
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding-bottom: 26px;
      li{
        width: max-content;
        margin-right: 40px;
        a{
          text-decoration: none;
          font-family: 'Roboto';
           font-style: normal;
           font-weight: 500;
           font-size: 16px;
           line-height: 19px;
           color: #8B91A9;
           padding-bottom: 24px;
        }

      }
    }

  }
}

.utility__models_inner_right {
  margin-top: 70px;
}
.services__inventations{
  padding-top: 30px;
}
@media only screen and (max-width:1200px) {
  .utility__models .utility__models_inner{
    width: 60%;
  }
  
} 
@media only screen and (max-width:978px) {
  .utility__models .utility__models_inner_top{
    h1{
      font-size: var(--font-size24);
      line-height: 30px;
      text-align: center;
    }
  }
  .utility__models .utility__models_inner_menu{
    &::-webkit-scrollbar{
      display: none;
    }
    ul{
      border-bottom: 1px solid #DDE1EA !important;
    }
  }



}
@media only screen and (max-width:786px) {
  .utility__models{
    flex-direction: column;
    justify-content: center;
  }
  .utility__models .utility__models_inner{
    width: 100%;
  }
  .utility__models_inner_right{
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  .utility__models .utility__models_inner_top{
    flex-direction: column;
  }
  .utility__models .utility__models_inner_top .image{
    margin-right: 0;
    margin-bottom: 17px;
  }
  
}
@media only screen and (max-width:500px) {
  .utility__models .utility__models_inner_top{
      margin-left: 20px;
      margin-right: 20px;

  }
  .utility__models .utility__models_inner_menu{
    margin-left: 20px;
    margin-right: 20px;
  }
}