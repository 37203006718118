.agency__about__item {
  padding-left: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size18);
  line-height: 26px;
  color: #1d213f;
  list-style-type: none;
  position: relative;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 5px;
    left: 0;
    background-color: #0065dd;
    border-radius: 50%;
  }

  &__link {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 24px;
    color: #0065dd;
  }
}
@media only screen and (max-width: 370px) {
  .agency__about__item {
 font-weight: 400;
font-size: 16px;
line-height: 24px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
}