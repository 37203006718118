.InformationServiseReleases {
  background: #f1f2f5;
  padding-top: 26px;
  padding-bottom: 100px;
  .InformationServiseReleases_inner {
    margin-top: 26px;
    background: #ffffff;
    padding: 61px 70px 70px 70px;
    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size40);
      line-height: 50px;
      color: #1d213f;
      margin-bottom: 49px;
    }
    .InformationServiseReleases_inner_main {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      a {
        background-color: #fff;
        margin-bottom: 30px;
        margin-left: 10px;
        margin-right: 10px;
        &:hover {
          img:nth-last-child(1) {
            margin-top: auto;
          }
        }
      }
    }
  }
}
@media only screen and (max-width:500px)  {
  .InformationServiseReleases .InformationServiseReleases_inner{
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .InformationServiseReleases .InformationServiseReleases_inner h1{
    font-size: var(--font-size24);
    line-height: 30px;
  }
  .InformationServiseReleases .InformationServiseReleases_inner .InformationServiseReleases_inner_main a{
    padding: 5px;
  }
  
}