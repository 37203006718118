.Vacency {
  padding-bottom: 100px;
  background: #f1f2f5;
  padding-top: 35px;
  .Vacency_main {
    margin-top: 35px;
    background: #ffffff;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 63px;
    padding-bottom: 70px;
    .Vacency_main_reaspns{
      display: none;
      button{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        color: #8B91A9;
        background-color: unset;
        border: 0;
        margin-right: 30px;
      padding-bottom: 17px;
      }
      margin-bottom: 40px;
      margin-left: 20px;
      margin-right: 20px;
      border-bottom: 2px solid #DDE1EA;
    }
    .Vacency_main_title {
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size40);
        line-height: 47px;
        color: #1d213f;
        margin-bottom: 50px;
        margin-left: 70px;
      }
    }
    .Vacency_main_middle {
      margin-left: 70px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

    }
  }
}
.Vacency_main_middle_right {
  max-width: 230px;
  width: 100%;
  margin-right: 70px;
  .Vacency_main_middle_right_top {
    background: #f1f2f5;
    padding-top: 34px;
    padding-bottom: 35px;
    padding-left: 30px;
    padding-right: 53px;
    .Vacency_main_middle_right_top_title {
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size16);
        line-height: 21px;
        color: #1d213f;
        margin-bottom: 24px;
      }
    }
    .Vacency_main_middle_right_top_child {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 24px;
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size14);
        line-height: 24px;
        color: #1d213f;
        margin-left: 14px;
      }
      a {
        text-decoration: none;
        color: #0065dd;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
  .Vacency_main_middle_right_bottom {
    margin-top: 55px;
    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size14);
      line-height: 21px;
      color: #8b91a9;
    }
    .Vacency_main_middle_right_bottom_child {
      display: flex;
      align-items: center;
      img {
        margin-right: 15px;
      }
      span {
        margin-top: 24px;
        a {
          text-decoration: none;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size14);
          line-height: 21px;
          color: #1d213f;
          margin-left: 30px;
          margin-left: 0;
        }
      }
    }
  }
}

@media only  screen and (max-width:978px) {
   .Vacency_main_reaspns{
     display: block  !important;
   }
   .Vacency .Vacency_main .Vacency_main_title h1{
     font-size: var(--font-size24) !important;
     margin-left: 20px;
     margin-bottom: 32px;
   }

   .Vacency_main_middle_right{
    margin-left: auto;
    margin-right: auto;
  }
  .Vacency .Vacency_main{
    padding-top: 48px;
  }
}
@media only screen and(max-width:500px) {
  .Vacency_main_middle_right{
    max-width: none;
  }
  .Vacency_main_middle_right .Vacency_main_middle_right_top{
    margin-left: 20px;
    margin-right: 20px;
  }
  .Vacency_main_middle_right .Vacency_main_middle_right_bottom{
    margin-left: 20px;
    margin-right: 20px;
  }
  .Vacency .Vacency_main .Vacency_main_reaspns button{
    margin-right: 0;
  }
  .Vacency{
    margin-left: 20px;
    margin-right: 20px;
  }
}