.activity__tenders {
  background: #f1f2f5;
  padding: 26px 0 100px 0;

  &__inner {
    background-color: #fff;
    padding: 63px 70px;
    margin-top: 26px;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    margin-bottom: 57px;
    color: #1d213f;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size24);
    line-height: 30px;
    color: #000000;
    margin-bottom: 34px;
    max-width: 500px;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;
    max-width: 500px;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__right {
    background: #f1f2f5;
    padding: 42px 50px;
    box-sizing: border-box;
  }

  &__submition {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size18);
    line-height: 24px;
    color: #1d213f;
    margin-bottom: 34px;
    max-width: 241px;
  }

  &__bottom {
    max-width: 350px;
  }
}


@media only screen and (max-width: 1024px) {
  .activity__tenders {
     &__title {
      font-weight: 500;
    font-size: var(--font-size30);
      line-height: 30px;
    }
    &__subtitle {
      font-weight: 500;
      font-size: var(--font-size18);
      line-height: 24px;
    }
  &__right {
   margin-left: 50px;
  }
}
}



@media only screen and (max-width: 900px) {
  .activity__tenders {
    &__inner {
      background-color: #fff;
      padding: 40px 40px;
      margin-top: 26px;
    }

    &__title {
      font-weight: 500;
     font-size: var(--font-size24);
      line-height: 30px;
    }
    &__wrapper {
      flex-direction: column;
    }
    &__subtitle {
      font-weight: 500;
      font-size: var(--font-size18);
      line-height: 24px;
    }
    &__text {
      font-weight: 400;
      font-size: var(--font-size16);
      line-height: 24px;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
    &__right {
   margin-top: 50px;
   margin-left: 0;
  }
  }
}



@media only screen and (max-width: 370px) {
  .activity__tenders {
    &__inner {
      background-color: #fff;
      padding: 40px 20px;
      margin-top: 26px;
      margin-left: 20px;
      margin-right: 20px;
    }

    &__title {
      font-weight: 500;
      font-size: var(--font-size24);
      line-height: 30px;
    }
    &__wrapper {
      flex-direction: column;
    }
    &__subtitle {
      font-weight: 500;
      font-size: var(--font-size18);
      line-height: 24px;
    }
    &__text {
      font-weight: 400;
     font-size: var(--font-size16);
      line-height: 24px;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
     &__right {
       margin-left: 0;
  }
  }
}
