.department__item {
  &:not(:last-child) {
    padding-bottom: 41px;
    border-bottom: 1px solid #dde1ea;

    @media only screen and (max-width: 768px) {
      padding-bottom: 50px;
  }
  }

  &:not(:first-child) {
    padding-top: 43px;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size16);
    line-height: 21px;
    color: #1d213f;
    max-width: 230px;
    margin-bottom: 10px;

    @media only screen and (max-width: 768px) {
      max-width: 100%;
      margin-bottom: 30px;
      max-width: 280px;
  }
  }

  &__name {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 21px;
    max-width: 200px;
    color: #1d213f;
    margin-bottom: 10px;

    @media only screen and (max-width: 768px) {
      max-width: 100%;
      margin-bottom: 10px;
  }
  }

  &__job {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size16);
    line-height: 18px;
    color: #8b91a9;

    @media only screen and (max-width: 768px) {
      margin-bottom: 30px;
      display: block;
  }
  }

  &__mobile {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 21px;
    color: #1d213f;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 10px;
  }

  &__tel {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 21px;
    color: #1d213f;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    width: 133px;
  }

  &__mail {
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size14);
    line-height: 18px;
    text-decoration: none;
    display: block;
    padding-left: 30px;
    color: #1d213f;
    position: relative;

    &::before {
      content: "";
      width: 16px;
      height: 12px;
      background-repeat: no-repeat;
      background-image: url(../../assets/icons/mail.svg);
      position: absolute;
      left: 0;
      margin: auto;
    }
  }

  &__img {
    width: 130px;
    height: 110px;
    object-fit: cover;

    @media only screen and (max-width: 768px) {
      display: none;
  }
  }

  .mobile {
    padding-left: 30px;
    position: relative;

    &::before {
      content: "";
      width: 11px;
      height: 17px;
      background-repeat: no-repeat;
      background-image: url(../../assets/icons/mobile.svg);
      position: absolute;
      left: 0;
      margin: auto;
    }
  }

  .tel {
    padding-left: 30px;
    position: relative;

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-image: url(../../assets/icons/tel.svg);
      position: absolute;
      left: 0;
      margin: auto;
    }
  }
}
