.projects {
  background: #f1f2f5;
  padding: 26px 0 100px 0;

  li {
    margin-bottom: 30px;
    &::before {
      display: none !important;
      left: -3px !important;
    top: 9px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 26px 20px 80px 20px;
  }

  &__inner {
    background-color: #fff;
    padding: 65px 70px;
    margin-top: 26px;

    @media only screen and (max-width: 768px) {
      padding: 48px 20px 27px 20px;
      margin-bottom: 87px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 47px;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size24);
      line-height: 28px;
      margin-bottom: 28px;
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size24);
    line-height: 30px;
    max-width: 423px;
    margin-bottom: 34px;
    color: #1d213f;

    @media only screen and (max-width: 768px) {
      font-size: var(--font-size18);
      line-height: 24px;
      margin-bottom: 19px;
    }
  }

  h4 {
    max-width: 525px !important;
  }
  .parsed {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size18);
      line-height: 26px;
      max-width: 769px;
      color: #1d213f;

      @media only screen and (max-width: 768px) {
        font-size: var(--font-size16);
        line-height: 24px;
        margin-bottom: 28px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
  &__text {
    &.projects__rounded {
      padding-left: 30px;
      position: relative;

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #0065dd;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }

    &.projects__roundedd {
    }
  }
  li {
    padding-left: 0;
    position: relative;
    list-style-type: none;
        font-style: normal;
      font-weight: normal;
      font-size: var(--font-size18);
      line-height: 26px;
      max-width: 769px;
      color: #1d213f;

      @media only screen and (max-width: 768px) {
        font-size: var(--font-size16);
        line-height: 24px;
        margin-bottom: 28px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: #0065dd;
      border-radius: 50%;
      position: absolute;
      // bottom: 10px;
      left: 0;
    }
  }
  &__holder {
    max-width: 800px;
  }
}
