table{
    border-collapse: collapse;
    margin-bottom: 70px;
    tr{
        th{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #1D213F;
            text-align: left;
            background: #F6F6F8;
            border-radius: 5px;
            padding-top: 26px;
            padding-bottom: 19px;
            padding-left: 20px;
        }
        td{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #1D213F;
            width: 300px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
        }
    }
}