.InformationService {
  background: #f1f2f5;
  padding-top: 26px;
  padding-bottom: 100px;
  .InformationService_inner {
    margin-top: 26px;
    background: #ffffff;
    padding: 63px 0px 56px 70px;
    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size40);
      line-height: 47px;
      color: #1d213f;
      margin-bottom: 44px;
    }
    .InformationService_inner_main {
      display: flex;
      align-items: center;
      .InformationService_inner_left {
        display: flex;
        flex-direction: column;
        margin-right: 100px;
      }
      .InformationService_inner_right {
        display: flex;
        flex-direction: column;
      }
      .InformationService_inner_item {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-size18);
        line-height: 40px;
        color: #0065dd;
        text-decoration: none;
      }
    }
  }
}
