.BreedingAchievementsRules{
    .BreedingAchievementsRules_top{
        h1{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            color: #000000;
            margin-bottom: 11px;
        }
        p{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #1D213F;
            margin-bottom: 85px;
        }
    }
    .BreedingAchievementsRules_bottom{
        display: flex;
        align-items: center;
        margin-bottom: 87px;
        .BreedingAchievementsRules_bottom_left{
            width: 55%;
            h2{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                color: #000000;
                margin-bottom: 22px;
            }
        }
        .BreedingAchievementsRules_bottom_right{
            width: 46%;
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                margin-bottom: 30px;
            }
        }
    }
}