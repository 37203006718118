.service__component {
  // &__wrappper{
  //     display: flex;
  // }

  &__holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 160px;
    margin-right: 30px;
    background: #f1f2f5;
    position: sticky;
    top: 90px;

    // img {
    //     width: 69px;
    //     height: 90px;
    // }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    background-color: #fff;
    margin-right: 30px;
    padding: 70px;
  }

  &__text {
    max-width: 541px;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__subtext {
    max-width: 800px;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size18);
    line-height: 26px;
    color: #1d213f;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size40);
    line-height: 47px;
    color: #1d213f;
    margin-bottom: 21px;
  }

  &__video {
    background: #ffffff;
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size16);
      line-height: 19px;
      margin-bottom: 4px;
      color: #8b91a9;
    }
  }

  &__video__title {
    font-style: normal;
    font-weight: normal;
    margin-top: 25px;
    font-size: var(--font-size16);
    line-height: 19px;
    margin-bottom: 4px;
    color: #1d213f;
  }

  &__btn {
    background: #0065dd;
    font-style: normal;
    font-weight: bold;
    font-size: var(--font-size14);
    line-height: 16px;
    text-transform: uppercase;
    padding: 22px 70px;
    border: none;
    cursor: pointer;
    outline: none;
    color: #ffffff;
    margin-top: 30px;
  }
}
